export const Options = {
  ambientColor: 0xffffd0,
  clearcoat: 1,
  clearcoatNormalScale: 0.1,
  clearcoatRoughness: 0.45,

  autoRotateSpeed: 1,
  bloomRadius: 0.46,
  bloomStrength: 0.48,
  bloomThreshold: 0,
  cameraLightColor: 0xc327d8,
  cameraLightDistance: -7,
  cameraLightIntensity: 0.04,
  color: 0xffffff,
  colorB: 0.18,
  colorG: 0.04,
  colorR: 0.04,
  darkAmbient: 0x111144,
  envMapIntensity: 3,
  exposure: 1,
  helperSize: .25,
  horizontalMovement: -0.1,
  hourglassRotateSpeed: 162,
  ior: 1.44,
  light1color: 0x66185a,
  light2color: 0x9a3392,
  lightIntensity: 0.4,
  lightSpeed: 0,
  metalness: 0,
  normalRepeat: 1,
  normalScale: 1,
  opacity: 1,
  reflectivity: 0,
  roughness: 0.45,
  sheen: 0,
  specularColor: 0xffffff,
  specularIntensity: 3,
  sunLightIntensity: 0.5,
  thickness: 0.25,
  transmission: 0.95,
  verticalMovement: 0,
  mouseMovement: 0.25,
  mobileShift: 0.7,
  mobileScale: .4,

  helpers: true,
};
