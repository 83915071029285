import soundtrack from "../audio/daze_ambient_score.mp3";
let isMuted, isPlaying, sound;

export const Sounds = () => {
  var soundIcon = document.getElementById("soundToggle");

  isMuted = true;

  function muteToggle() {
    if (isMuted == false) {
      console.log("muting");
      soundIcon.classList.add("muted");
      soundIcon.classList.remove("unmuted");
      isMuted = true;
      const currentVolume = sound.volume();
      sound.fade(currentVolume, 0, 2000);
    } else if (isMuted == true) {
      console.log("unmuting");
      soundIcon.classList.add("unmuted");
      soundIcon.classList.remove("muted");
      isMuted = false;

      sound.play();
      sound.fade(0, 3, 5000);
    }
  }

  soundIcon.onclick = function() {
    muteToggle();
  };

  sound = new Howl({
    src: [soundtrack],
    autoplay: true,
    loop: true,
    volume: 0,
    onend: function() {}
  });

  document.onmousedown = function() {
    if (isMuted == false) {
      const currentVolume = sound.volume();
      sound.fade(currentVolume, 1, 5000);
    } else if (isMuted == true) {
    }
  };
};
