// site js

import { Howl, Howler } from "howler";
import { Forms } from "./static/js/forms.js";
import { Sounds } from "./static/js/sounds.js";

import { Menu } from "./static/js/menu";
import { Poem } from "./static/js/poem.js";
import { BigScene } from "./static/js/bigScene";

// ui init
Sounds();
Forms();
