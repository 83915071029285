// ----------------------------- //
//   Splash Page Big ThreeJS  //
// ------------------------------ //

import { Options } from "./options.js";

// assets

import {
  sky_ft,
  sky_bk,
  sky_up,
  sky_dn,
  sky_lf,
  sky_rt,
  hourglass,
  normal,
  whiteTexture
} from "./assets.js";

const DEBUG = false; // Set to false in production

if (DEBUG) {
  window.THREE = THREE;
}

import {
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
  Mesh,
  Color,
  SphereBufferGeometry,
  HemisphereLight,
  DirectionalLight,
  DirectionalLightHelper,
  ShaderMaterial,
  Clock
} from "three";

import * as dat from "dat.gui";
import * as Stats from "stats.js";
import * as THREE from "three"; //REMOVE this in production

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { SubsurfaceScatteringShader } from "three/examples/jsm/shaders/SubsurfaceScatteringShader";

// post processing

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";

let container,
  camera,
  renderer,
  composer,
  controls,
  gui,
  loadingManager,
  geometry,
  material,
  mesh,
  isMobile,
  textureCube,
  uniforms,
  orbit,
  scene,
  time,
  clock,
  stats,
  objectsToRaycast;

let pointLight1, pointLight2, directionalLight, cameraLight;
let pointLightHelper1,
  pointLightHelper2,
  directionalLightHelper,
  cameraLightHelper;

const mouse = new THREE.Vector2();
const target = new THREE.Vector2();
const windowHalf = new THREE.Vector2(
  window.innerWidth / 2,
  window.innerHeight / 2
);

// 3js init

mobileCheck();
threeScene();

function threeScene() {
  const container = document.querySelector(".scene");

  clock = new Clock(true);
  time = 0;

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.toneMapping = THREE.ReinhardToneMapping;

  container.appendChild(renderer.domElement);

  scene = new THREE.Scene();

  const aspect = container.clientWidth / container.clientHeight;
  camera = new PerspectiveCamera(35, aspect, 0.1, 1000);

  scene.add(camera);
  camera.position.set(0, 0, 5);

  if (isMobile) {
    camera.position.set(0, 0.7, 5);
  }

  scene.add(camera);

  createControls();

  // background
  createBackground();
  scene.background = textureCube;

  // light it up
  createLights();

  const renderScene = new RenderPass(scene, camera);

  const bloomPass = new UnrealBloomPass(
    new THREE.Vector2(window.innerWidth, window.innerHeight),
    1.5,
    0.4,
    0.85
  );

  bloomPass.threshold = Options.bloomThreshold;
  bloomPass.strength = Options.bloomStrength;
  bloomPass.radius = Options.bloomRadius;

  composer = new EffectComposer(renderer);
  composer.setPixelRatio(window.devicePixelRatio);
  composer.addPass(renderScene);
  composer.addPass(bloomPass);

  createMaterial();
  createGeometries();

  window.scene = scene;
  window.camera = camera;
  window.controls = controls;

  if (DEBUG) {
    initGui();

    // toggle editor
    document.onkeyup = function(e) {
      if (e.altKey && e.which == 90) {
        // alt + d
        alert("✨ Dazed Mode Toggled ✨");
        document.querySelector(".dg").classList.toggle("hide");
      }
    };

    //stats = Stats.default();
    //document.body.appendChild(stats.dom);

    function initGui() {
      gui = new dat.GUI();
      window.gui = gui;
      document.querySelector(".dg").style.zIndex = 999999; //fix dat.gui hidden

      var folderMaterial = gui.addFolder("⌛material");
      var folderLighting = gui.addFolder("💡 lighting");
      var folderAnimation = gui.addFolder("💫 animation");
      var folderBloom = gui.addFolder("🌸 bloom");
      var folderPoem = gui.addFolder("✍️ poem");

      folderLighting.addColor(Options, "light1color").onChange(function() {
        pointLight1.color.set(Options.light1color);
      });

      folderLighting.addColor(Options, "light2color").onChange(function() {
        pointLight2.color.set(Options.light2color);
      });

      folderLighting
        .add(Options, "lightIntensity", 0, 10, 0.1)
        .onChange(function() {
          pointLight1.intensity = Options.lightIntensity;
          pointLight2.intensity = Options.lightIntensity;
        });

      folderLighting.addColor(Options, "cameraLightColor").onChange(function() {
        cameraLight.color.set(Options.cameraLightColor);
      });

      folderLighting
        .add(Options, "cameraLightIntensity", 0, 1, 0.01)
        .onChange(function() {
          cameraLight.intensity = Options.cameraLightIntensity;
        });

      folderLighting
        .add(Options, "sunLightIntensity", 0, 1, 0.01)
        .onChange(function() {
          directionalLight.intensity = Options.sunLightIntensity;
        });

      const ThicknessControls = function() {
        this.distortion = uniforms["thicknessDistortion"].value;
        this.ambient = uniforms["thicknessAmbient"].value;
        this.attenuation = uniforms["thicknessAttenuation"].value;
        this.power = uniforms["thicknessPower"].value;
        this.scale = uniforms["thicknessScale"].value;
        this.colorOne = uniforms["thicknessColor"].value;
      };

      const thicknessControls = new ThicknessControls();
      folderAnimation
        .add(Options, "lightSpeed", 0, 20, 0.1)
        .onChange(function() {
          material.sheen = Options.lightSpeed;
          const timer = 0.0001 * Date.now();
          pointLight1.position.x =
            Math.sin(timer * Options.lightSpeed) * -0.666;
          pointLight1.position.z = Math.cos(timer * Options.lightSpeed) * 1;
          pointLight1.position.y = Math.cos(timer * Options.lightSpeed) * 0.2;

          pointLight2.position.x = Math.sin(timer * Options.lightSpeed) * 0.666;
          pointLight2.position.z = Math.cos(timer * Options.lightSpeed) * -1;
          pointLight1.position.y = Math.cos(timer * Options.lightSpeed) * -0.2;
        });
      folderAnimation
        .add(Options, "verticalMovement", -5, 5, 0.1)
        .onChange(function() {
          material.sheen = Options.lightSpeed;
          const timer = 0.0001 * Date.now();
          pointLight1.position.z =
            Math.cos(timer * Options.lightSpeed) * Options.verticalMovement;
          pointLight2.position.z =
            Math.cos(timer * Options.lightSpeed) * -Options.verticalMovement;
        });
      folderAnimation
        .add(Options, "horizontalMovement", -1, 1, 0.1)
        .onChange(function() {
          material.sheen = Options.lightSpeed;
          const timer = 0.0001 * Date.now();
          pointLight1.position.x =
            Math.sin(timer * Options.lightSpeed) * -Options.horizontalMovement;
          pointLight1.position.y =
            Math.cos(timer * Options.lightSpeed) * Options.horizontalMovement;

          pointLight2.position.x =
            Math.sin(timer * Options.lightSpeed) * Options.horizontalMovement;
          pointLight1.position.y =
            Math.cos(timer * Options.lightSpeed) * -Options.horizontalMovement;
        });
      folderAnimation
        .add(Options, "hourglassRotateSpeed", 0.01, 200, 1)
        .onChange(function() {
          if (mesh)
            mesh.rotation.y =
              (performance.now() / Options.hourglassRotateSpeed) * 0.01;
        });
      folderAnimation
        .add(Options, "autoRotateSpeed", 0.01, 10, 1)
        .onChange(function() {
          controls.autoRotateSpeed = Options.autoRotateSpeed;
        });

      folderMaterial
        .add(thicknessControls, "distortion")
        .min(0.01)
        .max(1)
        .step(0.01)
        .onChange(function() {
          uniforms["thicknessDistortion"].value = thicknessControls.distortion;
          console.log("distortion");
        });
      folderMaterial
        .add(thicknessControls, "ambient")
        .min(0.01)
        .max(5.0)
        .step(0.05)
        .onChange(function() {
          uniforms["thicknessAmbient"].value = thicknessControls.ambient;
        });
      folderMaterial
        .add(thicknessControls, "attenuation")
        .min(0.01)
        .max(5.0)
        .step(0.05)
        .onChange(function() {
          uniforms["thicknessAttenuation"].value =
            thicknessControls.attenuation;
        });
      folderMaterial
        .add(thicknessControls, "power")
        .min(0.01)
        .max(16.0)
        .step(0.1)
        .onChange(function() {
          uniforms["thicknessPower"].value = thicknessControls.power;
        });
      folderMaterial
        .add(thicknessControls, "scale")
        .min(0.01)
        .max(50.0)
        .step(0.1)
        .onChange(function() {
          uniforms["thicknessScale"].value = thicknessControls.scale;
        });

      folderMaterial
        .add(Options, "colorR")
        .min(0)
        .max(1)
        .step(0.01)
        .onChange(function() {
          uniforms["thicknessColor"].value = new THREE.Vector3(
            Options.colorR,
            Options.colorG,
            Options.colorB
          );
        });
      folderMaterial
        .add(Options, "colorG")
        .min(0)
        .max(1)
        .step(0.01)
        .onChange(function() {
          uniforms["thicknessColor"].value = new THREE.Vector3(
            Options.colorR,
            Options.colorG,
            Options.colorB
          );
        });
      folderMaterial
        .add(Options, "colorB")
        .min(0)
        .max(1)
        .step(0.01)
        .onChange(function() {
          uniforms["thicknessColor"].value = new THREE.Vector3(
            Options.colorR,
            Options.colorG,
            Options.colorB
          );
        });

      folderBloom.add(Options, "exposure", 0.1, 2).onChange(function(value) {
        renderer.toneMappingExposure = Math.pow(value, 4.0);
      });

      folderBloom
        .add(Options, "bloomThreshold", 0.0, 1.0)
        .onChange(function(value) {
          bloomPass.threshold = Number(value);
        });

      folderBloom
        .add(Options, "bloomStrength", 0.0, 3.0)
        .onChange(function(value) {
          bloomPass.strength = Number(value);
        });

      folderBloom
        .add(Options, "bloomRadius", 0.0, 1.0)
        .step(0.01)
        .onChange(function(value) {
          bloomPass.radius = Number(value);
        });

      var controller = new (function() {
        this.poem = true;
      })();

      const animatedText = document.querySelector("#poem");

      if (controller.poem) {
      }

      folderPoem
        .add(controller, "poem")
        .listen()
        .onChange(function() {
          animatedText.classList.toggle("hide");
        });

      var helpersControl = new (function() {
        this.helpers = Options.helpers;
      })();

      folderLighting
        .add(helpersControl, "helpers")
        .listen()
        .onChange(function() {
          if (helpersControl.helpers) {
            scene.add(
              directionalLightHelper,
              pointLightHelper1,
              pointLightHelper2,
              cameraLightHelper
            );
          } else if (!helpersControl.helpers) {
            scene.remove(
              directionalLightHelper,
              pointLightHelper1,
              pointLightHelper2,
              cameraLightHelper
            );
          }
        });

      var hideModel = new (function() {
        this.hourglass = true;
      })();

      folderMaterial
        .add(hideModel, "hourglass")
        .listen()
        .onChange(function() {
          console.log(hideModel.hourglass);
          if (hideModel.hourglass) {
            scene.add(mesh);
          } else if (!hideModel.hourglass) {
            scene.remove(mesh);
          }
        });

      gui.close();
    }
  }

  animate();
}
function createBackground() {
  const bgLoader = new THREE.CubeTextureLoader();
  textureCube = bgLoader.load([sky_ft, sky_bk, sky_up, sky_dn, sky_rt, sky_lf]);
  textureCube.encoding = THREE.sRGBEncoding;
  textureCube.mapping = THREE.CubeRefractionMapping;
  textureCube.scale = 1;
}
function createLights() {
  directionalLight = new THREE.DirectionalLight(
    Options.ambientColor,
    Options.sunLightIntensity
  );
  directionalLight.color.set(Options.darkAmbient);
  directionalLight.position.set(0.1, 0.1, -0.1).normalize();

  pointLight1 = new THREE.PointLight(
    Options.light1color,
    Options.lightSize,
    300
  );

  pointLight1.color.set(Options.light1color);
  pointLight1.intensity = Options.lightIntensity;
  pointLight1.position.x = 0.666;
  pointLight1.position.y = 0;
  pointLight1.position.z = 0.666;

  pointLight2 = new THREE.PointLight(
    Options.light2color,
    Options.lightSize,
    300
  );
  pointLight2.color.set(Options.light2color);
  pointLight2.intensity = Options.lightIntensity;
  pointLight2.position.x = -0.666;
  pointLight2.position.y = 0;
  pointLight2.position.z = -0.666;

  if (isMobile) {
    //pointLight1.position.y = Options.mobileShift;
    //pointLight2.position.y = Options.mobileShift;

    pointLight1.position.x = 0.666 * Options.mobileScale;
    pointLight1.position.z = 0.666 * Options.mobileScale;

    pointLight2.position.x = -0.666 * Options.mobileScale;
    pointLight2.position.z = -0.666 * Options.mobileScale;
  }

  cameraLight = new THREE.PointLight(
    Options.cameraLightColor,
    Options.lightSize,
    300
  );
  cameraLight.intensity = Options.cameraLightIntensity;
  cameraLight.position.x = 0;
  cameraLight.position.y = 0;
  cameraLight.position.z = Options.cameraLightDistance;

  // light helpers
  pointLightHelper1 = new THREE.PointLightHelper(
    pointLight1,
    Options.helperSize
  );
  pointLightHelper2 = new THREE.PointLightHelper(
    pointLight2,
    Options.helperSize
  );
  directionalLightHelper = new THREE.DirectionalLightHelper(
    directionalLight,
    Options.helperSize
  );
  cameraLightHelper = new THREE.PointLightHelper(
    cameraLight,
    Options.helperSize
  );

  scene.add(directionalLight);
  scene.add(new THREE.AmbientLight(Options.darkAmbient, 1));
  scene.add(pointLight2);
  scene.add(pointLight1);

  camera.add(cameraLight);
}
function createMaterial() {
  // normal texture
  const textureLoader = new THREE.TextureLoader();

  const normalMapTexture = textureLoader.load(normal);
  normalMapTexture.wrapS = THREE.RepeatWrapping;
  normalMapTexture.wrapT = THREE.RepeatWrapping;
  normalMapTexture.repeat.set(Options.normalRepeat, Options.normalRepeat);

  const loaderTexture = new THREE.TextureLoader();
  const imgTexture = loaderTexture.load(whiteTexture);

  const thicknessTexture = normalMapTexture;
  imgTexture.wrapS = imgTexture.wrapT = THREE.RepeatWrapping;

  // subsurface scattering shader
  const shader = SubsurfaceScatteringShader;

  uniforms = THREE.UniformsUtils.clone(shader.uniforms);
  uniforms["map"].value = imgTexture;
  uniforms["diffuse"].value = new THREE.Vector3(1.0, 0.2, 0.2);
  uniforms["shininess"].value = 50;
  uniforms["thicknessMap"].value = thicknessTexture;
  uniforms["thicknessColor"].value = new THREE.Vector3(
    Options.colorR,
    Options.colorG,
    Options.colorB
  );
  uniforms["thicknessDistortion"].value = 0.53;
  uniforms["thicknessAmbient"].value = 0.3;
  uniforms["thicknessAttenuation"].value = 3.9;
  uniforms["thicknessPower"].value = 1.8;
  uniforms["thicknessScale"].value = 50;

  material = new THREE.ShaderMaterial({
    uniforms: uniforms,
    vertexShader: shader.vertexShader,
    fragmentShader: shader.fragmentShader,
    lights: true
  });
  material.extensions.derivatives = true;
}
function createGeometries() {
  loadingManager = new THREE.LoadingManager(() => {
    const loadingScreen = document.getElementById("loading-screen");
    loadingScreen.classList.add("fade-out");
    loadingScreen.addEventListener("transitionend", onTransitionEnd);
  });
  const loader = new GLTFLoader(loadingManager);
  loader.load(hourglass, gltf => {
    const hourglass = gltf.scene.children.find(
      mesh => mesh.name === "Hourglass"
    );

    // Copy Geometry
    geometry = hourglass.geometry.clone();
    geometry.translate(0, 0.25, 0);
    geometry.rotateX(-0.2);
    geometry.rotateZ(0.15);
    //geometry.rotateY(0);

    // Create Mesh & Place
    mesh = new THREE.Mesh(geometry, material);
    mesh.scale.set(0.33, 0.33, 0.33);

    if (isMobile) {
      mesh.scale.set(0.2, 0.2, 0.2);
      //mesh.position.set(0,0.7,0);
    }
    //mesh.receiveShadow = true;
    scene.add(mesh);

    // Discard Model
    hourglass.geometry.dispose();
    hourglass.material.dispose();

    // Camera Pivot

    orbit = new THREE.Object3D();
    orbit.rotation.order = "YXZ";
    orbit.position.copy(mesh.position);
    scene.add(orbit);

    let cameraDistance = 5;
    camera.position.z = cameraDistance;
    orbit.add(camera);
  });

  function onTransitionEnd(event) {
    const element = event.target;
    element.remove();
  }
}
function createControls() {
  controls = new OrbitControls(camera, renderer.domElement);
  controls.enabled = true;
  controls.enableDamping = true;
  controls.dampingFactor = 0.05;
  controls.enablePan = false;
  controls.enableZoom = false;
  controls.autoRotate = true;
  controls.maxPolarAngle = Math.PI / 1.7;
  controls.minPolarAngle = Math.PI / 2.9;
  controls.autoRotateSpeed = Options.autoRotateSpeed;

  if (isMobile) {
    controls.target = new THREE.Vector3(0, -Options.mobileShift, 0);
    controls.maxPolarAngle = Math.PI / 1.8;
    controls.minPolarAngle = Math.PI / 2.8;
  }
}
function sceneAnimation() {
  const timer = 0.0001 * Date.now();

  pointLight1.position.x =
    Math.sin(timer * Options.lightSpeed) * -Options.horizontalMovement;
  pointLight1.position.z =
    Math.cos(timer * Options.lightSpeed) * Options.verticalMovement;
  pointLight1.position.y =
    Math.cos(timer * Options.lightSpeed) * Options.horizontalMovement;

  pointLight2.position.x =
    Math.sin(timer * Options.lightSpeed) * Options.horizontalMovement;
  pointLight2.position.z =
    Math.cos(timer * Options.lightSpeed) * -Options.verticalMovement;
  pointLight2.position.y =
    Math.cos(timer * Options.lightSpeed) * -Options.horizontalMovement;

  // shift up a little bit for mobile

  if (isMobile) {
    pointLight1.position.x =
      Math.sin(timer * Options.lightSpeed) *
      -Options.horizontalMovement *
      Options.mobileScale;
    pointLight1.position.z =
      Math.cos(timer * Options.lightSpeed) *
      Options.verticalMovement *
      Options.mobileScale;
    pointLight1.position.y =
      Math.cos(timer * Options.lightSpeed) *
      Options.horizontalMovement *
      Options.mobileScale;

    pointLight2.position.x =
      Math.sin(timer * Options.lightSpeed) *
      Options.horizontalMovement *
      Options.mobileScale;
    pointLight2.position.z =
      Math.cos(timer * Options.lightSpeed) *
      -Options.verticalMovement *
      Options.mobileScale;
    pointLight2.position.y =
      Math.cos(timer * Options.lightSpeed) *
      -Options.horizontalMovement *
      Options.mobileScale;
  }

  if (mesh)
    mesh.rotation.y = (performance.now() / Options.hourglassRotateSpeed) * 0.01;

  controls.update();
}
function animate() {
  requestAnimationFrame(animate);
  sceneAnimation();

  // mouse movement animation

  //stats.update();
  composer.render();
}

function onWindowResize() {
  const width = window.innerWidth;
  const height = window.innerHeight;

  camera.aspect = width / height;
  camera.updateProjectionMatrix();

  renderer.setSize(width, height);
  composer.setSize(width, height);
}
window.addEventListener("resize", onWindowResize, false);

// helpers

function mobileCheck() {
  isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
  if (isMobile) {
    console.log("its a phone");
  }
}

export { BigScene };
