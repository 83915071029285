let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

let poem = document.getElementById("poem");
let lines = [
  "Daze makes time.",
  "What if we spent our time wisely?",
  "Let’s find time together."
];

// add poem to page
// lines.forEach(item => {
//   let span = document.createElement("h2");
//   span.innerText = item;
//   poem.appendChild(span);
// });

let lineCount = poem.childElementCount;
let lineTime = 2000; // line shows for this long
let lineTransition = 1500; // line transitions in and out
let lineDelay = 3000; // delay
let lineTotal = lineTime + lineTransition * 2 + lineDelay;
let sentences = Array.from(document.querySelector("#poem").children);

let first = poem.firstElementChild;
let current, next;
let currentTimer, nextTimer;

if (!isMobile) {
  // only add on desktop
  setTimeout(() => {
    startPoem();
  }, lineTransition);
  let clicky = document.querySelector("#poem").children;

  for (var item of document.querySelectorAll("#poem h2")) {
    item.addEventListener("click", poemNextLine);
  }
}

function startPoem() {
  if (sentences.length != 0) {
    first.classList.add("active");
    current = document.querySelector("#poem h2.active");
    next = current.nextElementSibling;

    setTimeout(advancePoem, lineDelay);
  } else {
    console.log("you should never see this...something went wrong...");
  }
}
function poemOut() {
  //console.log('fade out..');
  current.classList.remove("active");
}
function poemIn() {
  //console.log('fade in..');
  next.classList.add("active");
  current = document.querySelector("#poem h2.active");

  if (current.nextElementSibling) {
    next = current.nextElementSibling; // set the new next line
  } else {
    next = first; // if no next line, go to first line
  }
}
function advancePoem() {
  poemOut(); // fade out current line

  currentTimer = setTimeout(() => {
    poemIn();
  }, lineDelay); // wait for the transition to end before fading in next line

  nextTimer = setTimeout(advancePoem, lineTotal); // loop after delay
}
function poemNextLine() {
  // impatient much?
  event.preventDefault();
  console.log("⏭ Skipping poem forward");

  // clear current timers
  clearTimeout(nextTimer);
  clearTimeout(currentTimer);

  poemOut();
  currentTimer = setTimeout(() => {
    poemIn();
  }, 1000); // wait for the transition to end before fading in next line

  nextTimer = setTimeout(advancePoem, lineTotal);
}
